import { FormControlClassKey } from '@material-ui/core/FormControl';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { monochromeDark, monochromeDarkest, negative } from '../../abstracts/colours';
import { sourceSansPro } from '../../abstracts/constants';

export const formControlTheme: Partial<Record<FormControlClassKey, CSSProperties>> = {
  root: {
    minWidth: 'auto',
    position: 'static',
    transform: 'none',
    color: monochromeDarkest,
    fontFamily: sourceSansPro,
    fontWeight: 600,
    marginBottom: 8,
    fontSize: 16,
    lineHeight: '20px',
    '& .MuiInputLabel-shrink': {
      transform: 'none',
    },
    '& > .disabled': {
      opacity: 0.3,
    },
    '& > .textfield-label': {
      marginBottom: 8,
      '& > .optional-flag': {
        fontFamily: sourceSansPro,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
      },
      '& > div': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
        color: monochromeDark,
        '&.disabled': {
          opacity: 0.3,
        },
      },
      '& > .asterisk': {
        color: negative,
      },
    },
    '&.phone-input': {
      '& .phone-input-textfield-input': {
        width: '100%',
        borderRadius: '0 4px 4px 0',
      },
      '& .phone-input-dropdown': {
        maxWidth: 100,
        '& .select-input': {
          width: '100%',
          borderRight: 'none',
          borderRadius: '4px 0 0 4px',
        },
      },
    },
  },
};

export default {
  formControlTheme,
};
